import React, { useEffect, useState } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import "./TeamAdd.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const TeamEdit = () => {
  const { id } = useParams();
  const Navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    designation: "",
    img: null,
  });
  console.log(formData, "formData");

  const [existingImage, setExistingImage] = useState("");

  useEffect(() => {
    axios({
      url: "https://lunarsenterprises.com:6013/kdpetroleum/list/team-member",
      method: "POST",
      data: {
        t_id: id,
      },
    })
      .then((response) => {
        const { t_name, t_designation, t_image } = response.data.list[0];
        setFormData({
          name: t_name,
          designation: t_designation,
          img: null,
        });
        setExistingImage(t_image);
      })
      .catch((error) =>
        console.error("Error fetching team member data:", error)
      );
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      img: e.target.files[0],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("t_id", id);
    formdata.append("name", formData.name);
    formdata.append("designation", formData.designation);
    if (formData.img) {
      formdata.append("image", formData.img);
    } else {
      formdata.append("image", existingImage);
    }
    axios({
      url: "https://lunarsenterprises.com:6013/kdpetroleum/edit/team-member",
      method: "POST",
      data: formdata,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          Navigate("/Team");
          console.log(data.data.message, "message");
        } else {
          console.log(data.data.message, "message");
        }
      })
      .catch((error) => console.error("Error updating team member:", error));
  };

  return (
    <Container className="team-edit-container">
      <h2 className="text-center mb-4">Edit Team Member</h2>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formDesignation">
              <Form.Label>Designation</Form.Label>
              <Form.Select
                name="designation"
                value={formData.designation}
                onChange={handleInputChange}
                required
              >
                <option value="">Select designation</option>
                <option value="Chairman">Chairman</option>
                <option value="Secretary">Secretary</option>
                <option value="Treasurer">Treasurer</option>
                <option value="Manager">Designer</option>
                <option value="Team">Team</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="formImageUrl" className="mb-3">
          <Form.Label>Profile Image</Form.Label>
          {existingImage && (
            <div className="mb-2">
              <img
                src={`https://lunarsenterprises.com:6013/${existingImage}`}
                alt="Existing Team Member"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
          )}
          <Form.Control type="file" name="img" onChange={handleFileChange} />
        </Form.Group>
        <Button variant="primary" type="submit" className="w-100 mt-3">
          Update Team Member
        </Button>
      </Form>
    </Container>
  );
};

export default TeamEdit;
