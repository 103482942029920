import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "./Team.css";

const baseUrl = "https://lunarsenterprises.com:6013";

const TeamMemberItem = ({ member }) => {
  const navigate = useNavigate();

  const ProductDelete = (t_id) => {
    axios({
      url: `${baseUrl}/kdpetroleum/delete`,
      method: "POST",
      data: { t_id: t_id },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const handleEdit = (t_id) => {
    navigate(`/TeamEdit/${t_id}`);
  };

  return (
    <Col xs={12} lg={4} className="ezy__team12-each-features">
      <div className="ezy__team12-features-inner position-relative">
        <img
          src={baseUrl + member.t_image}
          alt="Our Team Member"
          className="img-fluids"
        />
        <div className="ezy__team12-features-content text-center">
          <h3 className="ezy__team12-title mb-1">{member.t_name}</h3>
          <p className="ezy__team12-sub-title">{member.t_designation}</p>
          <div className="icon-container">
            <FontAwesomeIcon
              icon={faEdit}
              className="edit-icon"
              onClick={() => handleEdit(member.t_id)}
              title="Edit Team Member"
            />
            <FontAwesomeIcon
              icon={faTrash}
              className="delete-icon"
              onClick={() => ProductDelete(member.t_id)}
              title="Delete Team Member"
            />
          </div>
        </div>
      </div>
    </Col>
  );
};

TeamMemberItem.propTypes = {
  member: PropTypes.object.isRequired,
};

const Team = () => {
  const [team, setTeam] = useState([]);
  const Navigate = useNavigate();

  useEffect(() => {
    TeamList();
  }, []);

  const TeamList = () => {
    axios({
      url: "https://lunarsenterprises.com:6013/kdpetroleum/list/team-member",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        setTeam(data.data.list);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  return (
    <section className="ezy__team12 light">
      <Container>
        <Row className="ezy__team12-separator text-right justify-content-end">
          <Col xs="auto">
            <h3 className="ezy__team12-heading text-right">Our Team</h3>
          </Col>
          <Col xs="auto">
            <Button
              variant="primary"
              className="ezy__team12-btn"
              onClick={() => Navigate("/TeamAdd")}
            >
              Add Team
            </Button>
          </Col>
        </Row>
        <Row>
          {team?.map((member, i) => (
            <TeamMemberItem member={member} key={i} />
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Team;
