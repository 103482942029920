import React, { useState } from "react";
import "./ForgotPassword.css";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const Navigate = useNavigate();
  const [isReset, setIsReset] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);

  const handleForgotPassword = () => {
    if (email) {
      let requestBody = {
        email: email,
      };
      axios({
        url: "https://lunarsenterprises.com:6013/kdpetroleum/forgot-password",
        method: "post",
        data: requestBody,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((data) => {
        if (data.data.result === true) {
          //   setResetRequested(true);
          setIsOtpSent(true);
          toast.success("OTP Sended Successfully");
        } else {
          toast.error(data.data.message);
        }
      });
    } else {
      alert("Please enter your email");
    }
  };

  const handleVerifyOtp = () => {
    if (otp) {
      let requestBody = {
        email: email,
        OTP: otp,
      };
      axios({
        url: "https://lunarsenterprises.com:6013/kdpetroleum/verify-otp",
        method: "post",
        data: requestBody,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((data) => {
        if (data.data.result === true) {
          setIsReset(true);
          toast.success(data.data.message);
        } else {
          toast.error(data.data.message);
        }
      });
    } else {
      alert("please type the otp");
    }
  };

  const handleChangePassword = () => {
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
    if (!isPasswordUnique(newPassword)) {
      alert(
        "Password must include uppercase, lowercase, number, and special character."
      );
      return;
    }
    let requestBody = {
      email: email,
      password: confirmPassword,
    };
    axios({
      url: "https://lunarsenterprises.com:6013/kdpetroleum/change-password",
      method: "post",
      data: requestBody,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((data) => {
      if (data.data.result === true) {
        Navigate("/");
        toast.success(data.data.message);
      } else {
        toast.error(data.data.message);
      }
    });
  };

  const isPasswordUnique = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    return hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;
  };

  return (
    <div className="fpw-container">
      <ToastContainer autoClose={3000} />
      <h2>{isReset ? "Change Password" : "Forgot Password"}</h2>

      {!isOtpSent ? (
        <div className="fpw-form">
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
          <button className="fpw-change-btn" onClick={handleForgotPassword}>
            Send OTP
          </button>
        </div>
      ) : !isReset ? (
        <div className="fpw-otp-form">
          <label>OTP</label>
          <input
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            placeholder="Enter OTP"
            required
          />
          <button className="fpw-change-btn" onClick={handleVerifyOtp}>
            Verify OTP
          </button>
        </div>
      ) : (
        <div className="fpw-change-form">
          <label>New Password</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Enter new password"
            required
          />
          <label>Confirm Password</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm new password"
            required
          />
          <button className="fpw-change-btn" onClick={handleChangePassword}>
            Change Password
          </button>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
