import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import "./Products.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

let baseUrl = "https://lunarsenterprises.com:6013/";

const ProductItem = ({ product }) => {
  const navigate = useNavigate();
  const ProductDelete = (p_id) => {
    axios({
      url: "https://lunarsenterprises.com:6013/kdpetroleum/delete",
      method: "POST",
      data: { p_id: p_id },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        window.location.reload();
        console.log(data.data.list);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  const handleEdit = () => {
    navigate(`/EditProduct/${product.p_id}`);
  };
  return (
    <Card
      className="ezy__epgrid3-card position-relative p-2"
      key={product.p_id}
    >
      <div className="ezy__epgrid1-fav-icon" onClick={handleEdit}>
        <FontAwesomeIcon icon={faEdit} />
      </div>
      <Card.Body>
        <div className="ezy__epgrid3-card-img d-flex justify-content-center align-items-center h-50 px-4">
          <a href="#!">
            <img src={baseUrl + product.p_image} alt="..." />
          </a>
        </div>
      </Card.Body>
      <Card.Body className="h-100 pt-0 text-start">
        <a href="#!">
          <h5 className="ezy__epgrid3-title">{product.p_name}</h5>
        </a>
        <h5 className="ezy__epgrid3-price my-2">₹{product.p_price}</h5>
        <div className="ezy__epgrid3-details d-flex justify-content-between align-items-center">
          <div className="ezy__epgrid3-ratings"></div>
          <div onClick={() => ProductDelete(product.p_id)}>
            <h5 className="ezy__epgrid3-cart">
              <FontAwesomeIcon icon={faTrash} />
            </h5>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

ProductItem.propTypes = {
  product: PropTypes.object.isRequired,
};

const Products = () => {
  const [products, setProducts] = useState([]);
  const Navigate = useNavigate();
  useEffect(() => {
    ProductList();
  }, []);
  const ProductList = () => {
    axios({
      url: "https://lunarsenterprises.com:6013/kdpetroleum/list/products",
      method: "POST",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        setProducts(data.data.list);
        console.log(data.data.list);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  return (
    <section className="ezy__epgrid3 light">
      <Container>
        <Row className="align-items-center">
          <Col xs>
            <h2 className="ezy__epgrid3-heading">Product</h2>
          </Col>
          <Col xs={"auto"}>
            <Button
              variant="primary"
              className="ezy__epgrid3-btn"
              role="button"
              onClick={() => Navigate("/AddProduct")}
            >
              Add Product
            </Button>
          </Col>
        </Row>
        <Row className="ezy__epgrid3-row text-center justify-content-xl-center mt-5">
          {products?.map((product, i) => (
            <Col md={6} lg={4} xl={3} className="px-2 my-2" key={i}>
              <ProductItem product={product} />{" "}
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};
export default Products;
