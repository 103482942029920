import React, { useState, useEffect } from "react";
import "./AddProduct.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const EditProduct = () => {
  const { id: productId } = useParams();
  const navigate = useNavigate();

  const [productData, setProductData] = useState({
    name: "",
    description: "",
    price: "",
    image: null,
    stocks: "",
    category: "",
    comp_product: 0,
  });
  const [categories, setCategories] = useState([]);
  const [exist, setExist] = useState("");

  useEffect(() => {
    fetchProductData();
    fetchCategoryList();
  }, [productId]);

  const fetchProductData = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:6013/kdpetroleum/list/products",
        { p_id: productId },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.result) {
        const product = response.data.list[0];
        setProductData({
          name: product.p_name,
          description: product.p_description,
          price: product.p_price,
          stocks: product.p_stocks,
          category: product.category || "",
          comp_product: product.p_company_product || 0,
        });
        setExist(product.p_image);
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const fetchCategoryList = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:6013/kdpetroleum/list/category",
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      setCategories(response.data.list);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData({
      ...productData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    setProductData({
      ...productData,
      comp_product: e.target.checked ? 1 : 0,
    });
  };

  const handleImageChange = (e) => {
    setProductData({
      ...productData,
      image: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", productData.name);
    formData.append("price", productData.price);
    formData.append("description", productData.description);
    formData.append("stocks", productData.stocks);
    formData.append("category", productData.category);
    formData.append("comp_product", productData.comp_product);
    formData.append("p_id", productId);

    if (productData.image) {
      formData.append("image", productData.image);
    }

    axios({
      url: "https://lunarsenterprises.com:6013/kdpetroleum/edit/product",
      method: "POST",
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        if (response.data.result === true) {
          navigate("/products");
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
          console.error(response.data.message);
        }
      })
      .catch((error) => console.error("Error updating product:", error));
  };

  return (
    <div className="add-product-form-container">
      <ToastContainer />
      <h2>Edit Product</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name" className="add-product-form-label">
            Name:
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={productData.name}
            onChange={handleChange}
            className="add-product-input"
          />
        </div>
        <div>
          <label htmlFor="description" className="add-product-form-label">
            Description:
          </label>
          <textarea
            id="description"
            name="description"
            value={productData.description}
            onChange={handleChange}
            className="add-product-textarea"
          />
        </div>
        <div>
          <label htmlFor="price" className="add-product-form-label">
            Price:
          </label>
          <input
            type="number"
            id="price"
            name="price"
            value={productData.price}
            onChange={handleChange}
            className="add-product-input"
          />
        </div>
        <div>
          <label htmlFor="stocks" className="add-product-form-label">
            Stocks:
          </label>
          <input
            type="text"
            id="stocks"
            name="stocks"
            value={productData.stocks}
            onChange={handleChange}
            className="add-product-input"
          />
        </div>
        <div>
          <label htmlFor="image" className="add-product-form-label">
            Update Image:
          </label>
          <input
            type="file"
            id="image"
            name="image"
            accept="image/*"
            onChange={handleImageChange}
            className="add-product-file-input"
          />
          <div style={{ color: "red", fontSize: "12px" }}>{exist}</div>
        </div>
        <div>
          <label htmlFor="category" className="add-product-form-label">
            Category:
          </label>
          <select
            id="category"
            name="category"
            value={productData.category}
            onChange={handleChange}
            className="add-product-select"
          >
            <option value="">{productData.category}</option>
            {categories.map((category) => (
              <option key={category.cg_id} value={category.cg_id}>
                {category.cg_name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="comp_product" className="add-product-form-label">
            Company Product:
          </label>
          <input
            type="checkbox"
            id="comp_product"
            name="comp_product"
            checked={productData.comp_product === 1}
            onChange={handleCheckboxChange}
            className="add-product-checkbox"
          />
        </div>
        <button type="submit" className="add-product-submit-btn">
          Update Product
        </button>
      </form>
    </div>
  );
};

export default EditProduct;
