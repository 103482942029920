import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import {
  FaBars,
  FaHome,
  FaTable,
  FaChartLine,
  FaUser,
  FaWrench,
  FaLayerGroup,
  FaSignOutAlt,
  FaTeamspeak,
} from "react-icons/fa";
import "./Sidebar.css";
import { useNavigate } from "react-router-dom";
import { FaPeopleGroup } from "react-icons/fa6";

function Sidebar() {
  const Navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [isHeldOpen, setIsHeldOpen] = useState(true);

  const toggleHoldSidebar = () => {
    setIsHeldOpen(!isHeldOpen);
    setIsOpen(!isHeldOpen);
  };
  const handleLogOut = () => {
    localStorage.removeItem("isLoggedIn");
    Navigate("/");
  };
  return (
    <div
      className={`sidebar bg-dark text-white p-3 ${isOpen ? "open" : "closed"}`}
      onMouseEnter={() => !isHeldOpen && setIsOpen(true)}
      onMouseLeave={() => !isHeldOpen && setIsOpen(false)}
    >
      <div className="d-flex align-items-center justify-content-between">
        {isOpen && <h3 className="text-center">Petroleum</h3>}
        <button onClick={toggleHoldSidebar} className="btn btn-dark toggle-btn">
          <FaBars />
        </button>
      </div>

      <Nav className="flex-column mt-3">
        <Nav.Link
          href="/dashboard"
          className="text-white d-flex align-items-center"
        >
          <FaHome className="sidebar-icon" />
          {isOpen && <span className="ms-2">Dashboard</span>}
        </Nav.Link>
        <Nav.Link
          href="/products"
          className="text-white d-flex align-items-center"
        >
          <FaLayerGroup className="sidebar-icon" />
          {isOpen && <span className="ms-2">Products</span>}
        </Nav.Link>
        <Nav.Link href="/Team" className="text-white d-flex align-items-center">
          <FaPeopleGroup className="sidebar-icon" />
          {isOpen && <span className="ms-2">Team</span>}
        </Nav.Link>
        <Nav.Link
          href="/EnquiryList"
          className="text-white d-flex align-items-center"
        >
          <FaUser className="sidebar-icon" />
          {isOpen && <span className="ms-2">Enquiry Forms</span>}
        </Nav.Link>
        {/* <Nav.Link
          href="#tables"
          className="text-white d-flex align-items-center"
        >
          <FaTable className="sidebar-icon" />
          {isOpen && <span className="ms-2">Tables</span>}
        </Nav.Link>
        <Nav.Link
          href="#charts"
          className="text-white d-flex align-items-center"
        >
          <FaChartLine className="sidebar-icon" />
          {isOpen && <span className="ms-2">Charts</span>}
        </Nav.Link>
        <Nav.Link
          href="#widgets"
          className="text-white d-flex align-items-center"
        >
          <FaLayerGroup className="sidebar-icon" />
          {isOpen && <span className="ms-2">Widgets</span>}
        </Nav.Link> */}
        <Nav.Link
          onClick={handleLogOut}
          className="text-white d-flex align-items-center"
        >
          <FaSignOutAlt className="sidebar-icon" />
          {isOpen && <span className="ms-2">Logout</span>}
        </Nav.Link>
      </Nav>
    </div>
  );
}

export default Sidebar;
