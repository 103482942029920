import React, { useEffect, useState } from "react";
import "./AddProduct.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const AddProduct = () => {
  const navigate = useNavigate();
  const [productData, setProductData] = useState({
    name: "",
    description: "",
    price: "",
    image: null,
    stocks: "",
    category: "",
    comp_product: 0, // Default value for the checkbox
  });
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchCategoryList();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData({
      ...productData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    setProductData({
      ...productData,
      image: e.target.files[0],
    });
  };

  const handleCheckboxChange = (e) => {
    setProductData({
      ...productData,
      comp_product: e.target.checked ? 1 : 0, // Set 1 if checked, otherwise 0
    });
  };

  const fetchCategoryList = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:6013/kdpetroleum/list/category",
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      setCategories(response.data.list);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("name", productData.name);
    formdata.append("price", productData.price);
    formdata.append("description", productData.description);
    formdata.append("image", productData.image);
    formdata.append("stocks", productData.stocks);
    formdata.append("category", productData.category);
    formdata.append("comp_product", productData.comp_product);

    axios({
      url: "https://lunarsenterprises.com:6013/kdpetroleum/add/product",
      method: "POST",
      data: formdata,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
      .then((data) => {
        if (data.data.result === true) {
          navigate("/products");
          toast.success(data.data.message);
          console.log(data.data.message, "message");
        } else {
          toast.error(data.data.message);
          console.log(data.data.message, "message");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="add-product-form-container">
      <ToastContainer />
      <h2>Add Product</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name" className="add-product-form-label">
            Name:
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={productData.name}
            onChange={handleChange}
            required
            className="add-product-input"
          />
        </div>
        <div>
          <label htmlFor="description" className="add-product-form-label">
            Description/Size :
          </label>
          <textarea
            id="description"
            name="description"
            value={productData.description}
            onChange={handleChange}
            required
            placeholder="Size should be like 32,34,38"
            className="add-product-textarea"
          />
        </div>
        <div>
          <label htmlFor="price" className="add-product-form-label">
            Price:
          </label>
          <input
            type="number"
            id="price"
            name="price"
            value={productData.price}
            onChange={handleChange}
            required
            className="add-product-input"
          />
        </div>
        <div>
          <label htmlFor="stocks" className="add-product-form-label">
            Stocks:
          </label>
          <input
            type="text"
            id="stocks"
            name="stocks"
            value={productData.stocks}
            onChange={handleChange}
            required
            className="add-product-input"
          />
        </div>
        <div>
          <label htmlFor="image" className="add-product-form-label">
            Image:
          </label>
          <input
            type="file"
            id="image"
            name="image"
            accept="image/*"
            onChange={handleImageChange}
            required
            className="add-product-file-input"
          />
        </div>
        <div>
          <label htmlFor="category" className="add-product-form-label">
            Category:
          </label>
          <select
            id="category"
            name="category"
            value={productData.category}
            onChange={handleChange}
            required
            className="add-product-select"
          >
            <option value="">Select Category</option>
            {categories.map((category) => (
              <option key={category.cg_id} value={category.cg_id}>
                {category.cg_name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="comp_product" className="add-product-form-label">
            Company Product:
          </label>
          <input
            type="checkbox"
            id="comp_product"
            name="comp_product"
            checked={productData.comp_product === 1}
            onChange={handleCheckboxChange}
            className="add-product-checkbox"
          />
        </div>
        <button type="submit" className="add-product-submit-btn">
          Add Product
        </button>
      </form>
    </div>
  );
};

export default AddProduct;
