import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import Login from "./Components/Login/Login";
import Sidebar from "./Components/SideBar/Sidebar";
import Dashboard from "./Components/Dashboard/Dashboard";
import Navbars from "./Components/Navbar/Navbar";
import Products from "./Components/Products/Products";
import AddProduct from "./Components/Products/AddProduct";
import EditProduct from "./Components/Products/EditProduct";
import Team from "./Components/Testimonials/Team";
import TeamAdd from "./Components/Testimonials/TeamAdd";
import EnquiryList from "./Components/Enquiry/EnquiryList";
import TeamEdit from "./Components/Testimonials/TeamEdit";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "./Components/ForgetPassword/ForgetPassword";

function App() {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const hideHeaderFooterPaths = ["/", "/ForgetPassword"];

  useEffect(() => {
    const loggedInStatus = localStorage.getItem("isLoggedIn");
    setIsLoggedIn(loggedInStatus === "true");
  }, []);

  return (
    <div className="d-flex">
      <ToastContainer autoClose={3000} />
      {!hideHeaderFooterPaths.includes(location.pathname) && <Sidebar />}
      <div className="content" style={{ flex: 1 }}>
        {!hideHeaderFooterPaths.includes(location.pathname) && <Navbars />}
        <Routes>
          {!isLoggedIn ? (
            <>
              <Route path="/" element={<Login />} />
              <Route path="/ForgetPassword" element={<ForgotPassword />} />
            </>
          ) : (
            <>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/products" element={<Products />} />
              <Route path="/AddProduct" element={<AddProduct />} />
              <Route path="/EditProduct/:id" element={<EditProduct />} />
              <Route path="/Team" element={<Team />} />
              <Route path="/TeamAdd" element={<TeamAdd />} />
              <Route path="/TeamEdit/:id" element={<TeamEdit />} />
              <Route path="/EnquiryList" element={<EnquiryList />} />
              <Route path="/ForgetPassword" element={<ForgotPassword />} />
              <Route path="/" element={<Login />} />
            </>
          )}
        </Routes>
      </div>
    </div>
  );
}

export default App;
