import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  InputGroup,
} from "react-bootstrap";
import "./Login.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import batting from "../../Assets/petrol.jpg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignInForm = () => {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      LoginIn();
    }

    setValidated(true);
  };
  const LoginIn = () => {
    let requestBody = JSON.stringify({
      email: email,
      password: password,
    });
    axios({
      url: "https://lunarsenterprises.com:6013/kdpetroleum/login",
      method: "POST",
      data: requestBody,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((data) => {
      if (data.data.result === true) {
        toast.success(data.data.message);
        localStorage.setItem("isLoggedIn", true);
        navigate("/dashboard");
        window.location.reload();
      } else {
        toast.error(data.data.message);
        console.log(data.data.message, "error");
      }
    });
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const navigationss = () => {
    navigate("/ForgetPassword");
  };
  return (
    <>
      <ToastContainer autoClose={3000} />
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-4 mt-2" controlId="formEmail">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder="Enter Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            isInvalid={validated && !email}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a valid email address.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-2 mt-2" controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <InputGroup>
            <Form.Control
              required
              type={showPassword ? "text" : "password"}
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              isInvalid={validated && !password}
            />
            <InputGroup.Text
              onClick={togglePasswordVisibility}
              className="password-icon"
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">
              Please enter your password.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Button
          variant=""
          type="submit"
          className="ezy__signin10-btn-submit w-100"
        >
          Log In
        </Button>
        <Button
          onClick={navigationss}
          variant=""
          type="button"
          className="w-100"
        >
          Forget your password?
        </Button>
      </Form>
    </>
  );
};

const SignInFormCard = () => {
  return (
    <Card className="ezy__signin10-form-card">
      <Card.Body className="p-md-5">
        <h2 className="ezy__signin10-heading mb-3">
          Welcome to Kannur Petroleum
        </h2>

        <SignInForm />
      </Card.Body>
    </Card>
  );
};

const Login = () => {
  return (
    <section className="ezy__signin10 gray d-flex">
      <Container>
        <Row className="justify-content-between h-100">
          <Col md={4} lg={6}>
            <div
              className="ezy__signin10-bg-holder d-none d-md-block h-100"
              style={{
                backgroundImage: `url(${batting})`,
              }}
            />
          </Col>
          <Col md={8} lg={6} className="py-5">
            <Row className="align-items-center h-100 px-xl-5">
              <Col xs={12}>
                <SignInFormCard />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Login;
