import React, { useState } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import "./TeamAdd.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const TeamAdd = () => {
  const Navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    designation: "",
    img: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("New Team Member:", formData);
    const formdata = new FormData();
    formdata.append("name", formData.name);
    formdata.append("designation", formData.designation);
    formdata.append("image", formData.img);
    axios({
      url: "https://lunarsenterprises.com:6013/kdpetroleum/add/team-member",
      method: "POST",
      data: formdata,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    }).then((data) => {
      if (data.data.result === true) {
        Navigate("/Team");
        console.log(data.data.message, "message");
      } else {
        console.log(data.data.message, "message");
      }
    });
  };

  return (
    <Container className="team-add-container">
      <h2 className="text-center mb-4">Add New Team Member</h2>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formDesignation">
              <Form.Label>Designation</Form.Label>
              <Form.Select
                name="designation"
                value={formData.designation}
                onChange={handleInputChange}
                required
              >
                <option value="">Select designation</option>
                <option value="Chairman">Chairman</option>
                <option value="Secretary">Secretary</option>
                <option value="Treasurer">Treasurer</option>
                <option value="Manager">Designer</option>
                <option value="Team">Team</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="formImageUrl" className="mb-3">
          <Form.Label>Profile Image</Form.Label>
          <Form.Control
            type="file"
            name="img"
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                img: e.target.files[0],
              }))
            }
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="w-100 mt-3">
          Add Team Member
        </Button>
      </Form>
    </Container>
  );
};

export default TeamAdd;
